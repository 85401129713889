*{
margin: 0;
padding: 0;
}


@media(min-width:668px){
    .tavsif{
        width: 69%;
        font-size: 28px;
    }
    .tavsif b{font-size: 20px;}
    .ism{
        display: block;
        color: white ;
    }
    .tana{
    width: 100%;
    padding: 40px;
    background: linear-gradient(to  left, #ffffff, #f1f1f1 , #ffffff );
}
    .qart{
        margin: 54px auto;
    max-width: 980px;
    background-image: url("../img/bgg.jpg");
    background-size: 100% 100%;
    color: white;
    }

    .cBody{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: flex-start;
    }
    .rasm{
        margin: 0px 5% 0px 0px;
        width: 29%;
        transition: all;
        transition-duration: 0.5s;

    }
.rasm:hover{
    transform: scale(1.08);
}
    .rasm img{
        width: 100%;
    }
    .sarl{
        font-size: 28px;
        text-align: center;
        color: #4D4D4D;
        /* font-family: arial; */
        letter-spacing: 5px;
        word-spacing: 12px;
        margin: 43px auto;
    }
    .rasm h6{
        display: none;
    }
    .ulush{
        letter-spacing: 1px;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        top:30px;
        
    }
       
        .ulush1{
            letter-spacing: 1px;
        position: relative;
        top: 25px;
font-size: 20px;
    }
}
@media(max-width:667px){
    .tavsif{
        text-align: center;
        width: 100%;
        font-size: 20px;
    }
    .tavsif b{font-size: 20px;}
    .ism{
        display: block;
        color: white ;
    }
    .ulush1{
        position: relative;
        top: 20px;
    }
    .ulush{
        letter-spacing: 1px;
        font-size: 17px;
       
    }
    .tana{
        width: 100%;
        padding: 23px 0px;
        background: linear-gradient(to  left, #ffffff, #f1f1f1 , #ffffff );
    }
    .ism{
        display: none;
    }
    .rasm h6{
        color: white;
        font-size: 22px;
        text-align: center;
        display: block;
        margin: 22px 0px;
    }
    .qart{
        margin: 39px auto;
    width: 90%;
    background-image: url("../img/bgg.jpg");
    background-size: 100% 100%;
    color: white;
    }

    .cBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .rasm{
        width: 100%;
    }
    .rasm img{
        margin: 0px 0.5%;
        width: 99%;
    }
    .sarl{
        text-align: center;
        color: #4D4D4D;
        /* font-family: arial; */
        letter-spacing: 1px;
        word-spacing: 8px;
        margin: 38px 7%;

    }
}
@media(min-width:381px){
.cont{
    width: 100%;
padding: 44px;
}
.qabul{
    text-align: center;
    font-family: arial;
    font-size: 20px;
}
}
@media(max-width:380px){
    .cont{
        width: 100%;
    padding: 14px;
    }}
.row{
    margin-bottom: 44px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
}

.col3 button{
    margin: 15px 0px;
}

.qabul a{
    color: blue;
    text-decoration: none;
}
.col1{
    width: 100%;
    height: 340px;
    margin: 22px auto;
}
.col2{
    width: 300px;
    margin-top: 44px;
}
.col3{
    width: 380px;
    margin-top: 44px ;
}

*{box-sizing:border-box;}
a{text-decoration:none}
body{margin:0px; padding:0px; overflow-x: hidden;width:100%}
.page-wrapper
{
   margin-top:50px;
    position: absolute;
   
    width: 100%;
   
   }

footer p, footer strong, footer b, footer {
    color: #b0b0b0;
}
.hisoblagich{z-index: 3450 !important; position: fixed !important; bottom: 0vh !important; right: 0px !important; }
.footer-top {
    background: #02325f;
    background-size: cover;
    background-position: center;
    padding: 0 0 20px;
    font-family: rubik;
  padding-top:30px;
  position: relative;
  overflow: hidden
  
}
.flag{position: absolute; top:20px; left:-170px; width:400px; height:23px; transform: rotate(-45deg);}

.footer-top, .footer-bottom {
    background-color: #02325f;
}

.footer-bottom {
    padding: 15px 0;
    border-top: 1px solid #313646;
    background-color: #181828 !important;
    color: #b0b0b0;
    font-family: rubik;

color: #99a9b5;
    padding-top: 15px;
}

.footer-site-info {
    font-size: 92.86%;
}
#footer-navigation, #footer-navigation li a:hover, .custom-footer, .custom-footer li a:hover {
    color: white;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
    color: #99a9b5;
    padding-top: 15px;
}

.footer-bottom ul {
    margin: 0;
}
.inline-inside {
    font-size: 0;
    line-height: 0;
}
.clearfix:after, .clearfix:before {
    content: "";
    display: table;
}
#footer-menu li {
    display: inline-block;
    padding: 0 21px;
    position: relative;
    line-height: 1;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
    color: #99a9b5;
    padding-top: 15px;
}

#footer-navigation, #footer-navigation li a, .custom-footer, .custom-footer li a {
    color: #99a9b5;
    padding-top: 15px;
}
#footer-menu li+li:before {
    content: '';
    width: 0;
    height: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    font-size: 0;
    border-left: 1px solid #232234;
    border-right: 1px solid #333146;
}


navigation li a, .custom-footer, .custom-footer li a {
    color: #99a9b5;
    padding-top: 15px;
}

#footer-socials {
    text-align: right;
}

#footer-socials .socials {
    text-align: right;
    margin: 0 -7px;
    display: inline-block;
    vertical-align: middle;
}

a.socials-item {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 5px;
    line-height: 16px;
    padding: 10px;
    border-radius: 50%;
    background-color: #141421;
    border: 1px solid #2e2e4c;
    box-shadow: 3px 9px 16px rgb(0,0,0,0.4), -3px -3px 10px rgba(255,255,255, 0.06), inset 14px 14px 26px rgb(0,0,0,0.3), inset -3px -3px 15px rgba(255,255,255, 0.05);
}

.socials-item i {
    display: inline-block;
    font-weight: normal;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-size: 16px;
    text-align: center;
    vertical-align: top;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant: normal;
    font-weight: normal;
    text-rendering: auto;
}

.facebook {
    color: #4e64b5;
}

.twitter {
    color: #00aced;
}
.instagram {
    color: #9a8f62;
}
.youtube {
    color: #c82929;
}

.telegram {
    color: #2ca5e0;
}


a.socials-item:hover {
    box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
    border-color: rgba(255, 6, 224, 0.61);
    background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
}

.footer-bottom a:hover {
    color: white;
}


footer p, footer li {
    font-size: 15px;
    line-height: 22px;
}


.widget {
    margin-bottom: 50px;
}

.footer-title {
    margin-bottom: 40px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 15px;
    font-size: 16px;
    position: relative;
}

.footer-title:after {
    width: 50px;
    background: #fff;
    opacity: 0.2;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
}

.gem-contacts-item {
    padding-top: 10px;
    font-size: 15px;
}

.gem-contacts-item i {
    padding-right: 10px;
}

footer .widget ul {
    list-style: none;
    margin-top: 5px;
}

.posts li {
    border-bottom: 1px solid #393d50;
    padding-bottom: 12px;
    padding-top: 6px;
}

footer p, footer li {
    font-size: 15px;
    line-height: 22px;
}

.gem-pp-posts-date {
    color: #00bcd4;
    font-size: 89.5%;
}

footer p {
    line-height: 24px;
    margin-bottom: 10px;font-size: 15px;
}

.wpcf7-form-control-wrap .wpcf7-form-control {
    padding: 7px!important;
    width: 100%;
}

.wpcf7-form-control-wrap input {
    background: #02325f;
    overflow: hidden;
    border: 1px solid #2e344d;
    background-color: #02325f;
    box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03);
    border-radius: 5px;
    transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form-control-wrap input:hover {
    background-color: transparent;
    box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04);
    border-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.wpcf7 .wpcf7-form .contact-form-footer textarea {
    height: 160px;
    width: 100%;
}

.wpcf7-form-control-wrap textarea:hover {
    background-color: transparent;
    box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04);
    border-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.wpcf7-form-control-wrap textarea {
    background: #02325f;
    overflow: hidden;
    border: 1px solid #2e344d;
    background-color: #02325f;
    box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03);
    border-radius: 5px;
    transition: all 0.3s ease-in-out 0s;
}

textarea {
    overflow: auto;
    resize: vertical;
}

.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit {
    width: 100%;
    padding: 11px;
    margin: 0;
    line-height: 0;
}
.wpcf7-form .contact-form-footer .wpcf7-submit {
    background-color: #394050;
    color: #99a9b5;
    border: none;
    cursor: pointer;
    padding: 15px 40px;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.5s;
    letter-spacing: 2px;
    color: rgba(255,255,255,.5);
    box-shadow: none;
    text-transform: uppercase;
    outline: none !important;
    background-color: #02325f;
    border-radius: 5px;
    min-width: 140px;
    /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
    box-shadow: 3px 9px 16px rgb(0,0,0,0.4), -3px -3px 10px rgba(255,255,255, 0.06), inset 14px 14px 26px rgb(0,0,0,0.3), inset -3px -3px 15px rgba(255,255,255, 0.05);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #2e344d;
    transition: all 0.3s ease-in-out 0s;
}

.wpcf7-form input[type=submit] {
    height: 40px;
    line-height: 21px;
    padding: 10px 40px;
    font-size: 14px;
}

.posts li a {
    color: #99a9b5;
}

.wpcf7-form .contact-form-footer .wpcf7-submit:hover {
    box-shadow: 0 0px 20px rgba(84, 1, 74, 0.7);
    border-color: rgba(255, 6, 224, 0.61);
    background: linear-gradient(to right, rgba(255, 9, 9, 0.12941176470588237), #c000ffb5, rgba(255, 0, 94, 0.14));
    color: white;
}

img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
.widget_gallery a {
    display: inline-block;
}
footer .widget ul {
    list-style: none;
    margin-top: 5px;
}
.widget_gallery ul {
    padding-left: 0;
    display: table;
}

.widget_gallery li {
    display: inline-block;
    width: 33.33%;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    padding: 2px;
}

.widget_gallery.gallery-grid-4 li {
    width: 30%;
}


#waterdrop {
    height: 30px;
}

#waterdrop canvas {
    bottom: -70px !important;
}

.footer-site-info
{
  padding-top: 10px;
}
.bugLink{border:1px solid rgb(201, 201, 201); color:black; width:100%; padding:5px; box-sizing: border-box;  display:block; position:relative}
.bugCard{box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;}
.bugCard:hover{box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;}


/*
=====
DEPENDENCES
=====
*/

/* The component will reset button browser styles */

.r-button{
    --uirButtonBackgroundColor: var(--rButtonBackgroundColor, transparent);
    --uirButtonPadding: var(--rButtonPadding, var(--rButtonPaddingTop, 0) var(--rButtonPaddingRight, 0) var(--rButtonPaddingBottom, 0) var(--rButtonPaddingLeft, 0));
    --uirButtonBorderWidth: var(--rButtonBorderWidth, 0);
    --uirButtonBorderStyle: var(--rButtonBorderStyle, solid);
    --uirButtonBorderColor: var(--rButtonBorderColor, currentColor);
    --uirButtonFontFamily: var(--rButtonFontFamily, inherit);
    --uirButtonFontSize: var(--rButtonFontSize,  inherit);
    --uirButtonColor: var(--rButtonColor);
  
    background-color: var(--uirButtonBackgroundColor);
    padding: var(--uirButtonPadding);
  
    border-width: var(--uirButtonBorderWidth);
    border-style: var(--uirButtonBorderStyle);
    border-color: var(--uirButtonBorderColor);
  
    cursor: pointer;
  
    font-family: var(--uirButtonFontFamily);
    font-size: var(--uirButtonFontSize);
  }
  
  .r-button::-moz-focus-inner,
  .r-button[type="button"]::-moz-focus-inner,
  .r-button[type="reset"]::-moz-focus-inner,
  .r-button[type="submit"]::-moz-focus-inner {
    
    /* Remove the inner border and padding in Firefox. */
    
    border-style: none;
    padding: 0;
  }
  
  /* The component will reset browser's styles of link */
  
  .r-link{
      --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
      --uirLinkTextColor: var(--rLinkTextColor);
      --uirLinkTextDecoration: var(--rLinkTextDecoration, none);
  
      display: var(--uirLinkDisplay) !important;
      color: var(--uirLinkTextColor) !important;
      text-decoration: var(--uirLinkTextDecoration) !important;
  }
  
  /* The component will reset browser's styles of list */
  
  .r-list{
      --uirListPaddingLeft: var(--rListPaddingLeft, 0);
      --uirListMarginTop: var(--rListMarginTop, 0);
      --uirListMarginBottom: var(--rListMarginBottom, 0);
      --uirListListStyle: var(--rListListStyle, none);
  
      padding-left: var(--uirListPaddingLeft) !important;
      margin-top: var(--uirListMarginTop) !important;
      margin-bottom: var(--uirListMarginBottom) !important;
      list-style: var(--uirListListStyle) !important;
  }
  
  /* Basic styles of the hamburger component */
  
  .m-hamburger{  
    --uiHamburgerThickness: var(--hamburgerThickness, 4px);
  
    display: var(--hamburgerDisplay, inline-flex);
    width: var(--hamburgerWidth, 28px);
    height: var(--hamburgerHeight, 20px);
  
    position: relative;
  }
  
  .m-hamburger::before, 
  .m-hamburger::after, 
  .m-hamburger__label{
    width: 100%;
    height: var(--uiHamburgerThickness);
    border-radius: var(--hamburgerBorderRadius, 5px);
    background-color: var(--hamburgerBackgroundColor, currentColor);
  
    position: absolute;
    left: 0;
  }
  
  .m-hamburger::before, 
  .m-hamburger::after{
    content:"";
  }
  
  .m-hamburger::before{
    top: 0;
  }
  
  .m-hamburger::after{
    bottom: 0;
  }
  
  .m-hamburger__label{
    /* The calculation of middle hamburger button line position */
    
    top: calc(50% - calc(var(--uiHamburgerThickness) / 2));
  }
  
  /* helper to hide elements that are available only for screen readers. */
  
  .screen-reader{
    width: var(--screenReaderWidth, 1px) !important;
    height: var(--screenReaderHeight, 1px) !important;
    padding: var(--screenReaderPadding, 0) !important;
    border: var(--screenReaderBorder, none) !important;
  
    position: var(--screenReaderPosition, absolute) !important;
    clip: var(--screenReaderClip, rect(1px, 1px, 1px, 1px)) !important;
    overflow: var(--screenReaderOverflow, hidden) !important;
  }
  
  /*
  =====
  MENU STYLES
  =====
  */
  
  .menu{
    --uiMenuCircleSize: var(--menuCircleSize, 6.25rem);
    --uiMenuCircleOffset: var(--menuCircleOffset, 1rem);
    --uiMenuCircleHeight: calc(var(--uiMenuCircleSize) / 2);  
    --uiMenuCircleBackgroundColor: var(--menuCircleBackgroundColor, currentColor);
  
    --uiMenuHamburgerWidth: var(--menuHamburgerWidth, 1.75rem);
    --uiMenuHamburgerHeight: var(--menuHamburgerHeight, 1.25rem);
    
    --rButtonPaddingTop: calc(var(--uiMenuCircleHeight) - var(--uiMenuHamburgerHeight) - var(--uiMenuCircleOffset));
    
    --hamburgerWidth: var(--uiMenuHamburgerWidth);
    --hamburgerHeight: var(--uiMenuHamburgerHeight);
    --hamburgerBackgroundColor: var(--menuHamburgerBackgroundColor, #fff);
  
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;  
  
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: var(--menuZindex, 9998);
  }
  
  .menu__nav{
    box-sizing: border-box;
    width: 100%;
    height: 0;
  
    transition: opacity .2s ease-out;
    opacity: 0;
  
    display: flex;
    align-items: flex-end;
    z-index: 2;
  }
  
  .menu__list{
    width: 100%;
    max-height: 100%;
  
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .menu__toggle{
    box-sizing: border-box;
    width: var(--uiMenuCircleSize);
    height: var(--uiMenuCircleHeight);
    flex: none;
  
    position: relative;
    z-index: 0;
  }
  
  .menu__toggle::before{
    /*
    1. The font-size property is used to simplify calculations of the element's sizes and position
    2. The negative value for the bottom property need for hiding half of circle. 
    */
  
    content: "";
    width: 1em;
    height: 1em;
    font-size: var(--uiMenuCircleSize); /* 1 */
  
    background-color: var(--uiMenuCircleBackgroundColor);
    border-radius: 50%;
  
    position: absolute;
    bottom: -.5em; /* 2 */
    left: calc(50% - .5em);
    z-index: -1;
  
    will-change: width, height;
    transition: transform .25s cubic-bezier(0.04, -0.1, 0.29, 0.98),
                width .25s cubic-bezier(0.04, -0.1, 0.29, 0.98),
                height .25s cubic-bezier(0.04, -0.1, 0.29, 0.98);
  }
  
  /* styles of hamburger's animation */
  
  .m-hamburger::before, 
  .m-hamburger::after, 
  .m-hamburger__label{
    transition-timing-function: ease;
    transition-duration: .15s;  
  }
  
  .m-hamburger::before, 
  .m-hamburger::after{
    transition-property: transform;
  }
  
  .m-hamburger__label{
    transition-property: transform, opacity;
  }
  
  /*
  =====
  MENU STATES
  =====
  */
  
  .menu__toggle:focus{
    outline: var(--menuHaburgerOutlineOWidth, 2px) solid var(--menuHaburgerOutlineColor);
    outline-offset: var(--menuHaburgerOutlineOffset, 5px);
  }
  
  .menu_activated{
    height: 100%;
  }
  
  .menu_activated .menu__nav{
    flex-grow: 1;
    opacity: 1;
  
    will-change: opacity;
    transition-duration: .2s;
    transition-delay: .3s;
  }
  
  .menu_activated .menu__toggle::before{
    width: 100vmax;
    height: 100vmax;
    transform: translate3d(-50vh, -50vh, 0) scale(5);
    transition-duration: 1s;
  }
  
  .menu:not(.menu_activated) .menu__list{
    display: none;
  }
  
  .menu_activated .m-hamburger::before{
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(45deg);
  }
  
  .menu_activated .m-hamburger::after{
    transform: translate3d(0, -50%, 0) rotate(135deg);
    top: 50%;
  }
  
  .menu_activated .m-hamburger__label{
    transform: rotate(-45deg) translate3d(-.285em,-.3em, 0);
    opacity: 0;
  }
  
  /*
  =====
  SETTINGS
  =====
  */
  
  :root{
    --colorWhite: #fff;
    --colorMain: #02325f;
    --menuCircleBackgroundColor: var(--colorMain);
    --menuHamburgerBackgroundColor: var(--colorWhite);
    --menuHaburgerOutlineColor: var(--colorMain);
  }
  
  .menu_activated{
    --menuHaburgerOutlineColor: var(--colorWhite);
  }
  
  /*
  =====
  DEMO
  =====
  */
  
  
  .page{
    max-width: 380px;
    padding: 1rem;
    margin: auto;  
    text-align: center;
  }
  
  .page__name{
    display: block;
    font-size: 2rem;
    font-weight: 700;
  }
  
  .page__hint{
    display: block;
    line-height: 1.45;
    margin-top: 1rem;
  }
  
  .menu__group{
    --rLinkTextColor: var(--colorWhite);
  
    padding: .5rem 2rem; 
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .menu{display:none; z-index: 3455665;}

@media(max-width:1024px){
    .nav{margin-top:40px;}
.nav{width:100%;}
.rang{display:none}
.menu{display: flex;}
.navbarK{display: none;}
.logoForm{display: none;}
.manzil{position:relative; left:40px}
/* .formNews{display:none} */
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #02325f, rgba(85, 96, 243, 0.46)); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #02325f, rgba(85, 96, 243, 0.46)); 
  }

  .nav{height:77px}

  .blueLine{width:30%; margin: auto; height:6px; background-color:darkblue;position:relative; top:-25px}
*{font-family: 'Noto Sans', sans-serif;}
.D{display: none;}

@media (max-width:900px) {
  .D{display:block}
  .K{display: none;}
}



.containerGr{
    padding-top: 20px;
    width: 100%;
    background:radial-gradient(circle at top,  #fff 0,#f1f1f1 70%);
padding-bottom: 44px;
}
.aj{
    text-align: center;
margin-bottom: 38px;
    font-family: Times;
    font-size: 28px;
}
.dbr{
    margin:55px 20px ;
    color: #00000069;
    font-family: arial;
font-weight: bold;
}
.hhh{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.quti{
    width: 100%;
    height: 200px;
}

.grid h2{
    margin: 12px auto;
}
.grid:hover {
    /* background-color: rgb(51, 230, 236); */
    /* border-top: 2px solid #ec7a37; */
    /* border-bottom: 2px solid #ff4f69; */
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 1);
    transform: scale(1.025);
}
.button {

    background-color: darkblue;
    border-radius: 20px;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    padding: 0.75em 1.5em;
    text-decoration: none;
    width: 120px;


}
.uppercase{text-transform: capitalize;}
.button:hover{
    color: #fff;
background-color: rgb(238,122,54);
}
.button img{
    margin-left:4px;
}
.quti img{

    width: 30px;
    margin-right: 5px;
}
@media (min-width:619px){
    .grid{
        padding: 12px;

        width: 280px;
        border: 2px solid #5d4e65;
        height: 280px;
        text-align: center;
        transition: all .2s ease-in-out;
    }
    .grid h3{
        letter-spacing: 3px;
        font-family:arial;
        font-size: 18px;
    }
}
@media (max-width:618px){
    .grid{
        padding: 7px;
        margin-top: 11px;
        width: 280px;
        border: 2px solid #5d4e65;
        height: 270px;
        text-align: center;
        transition: all .2s ease-in-out;
    }
    .grid h2{
        font-size: 22px;
    }
    .grid h3{
        letter-spacing: 4px;
        font-family:arial;
        font-size: 16px;
    }
}
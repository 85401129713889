/* .news{ position:sticky; top:70px; left:0px; background-color:red; width:100%; height:600px; border-right: 1px solid rgb(216, 212, 212); padding:20px;}
.news ul{list-style:none}
.card{box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; position:relative;padding-bottom: 20px;}
.card:hover{box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;}
.text{margin:20px; }
.date{margin:20px;  position: absolute; bottom:-10px; right:10px;} */
.newsHeader{
    text-align: center;
position: sticky;
top:90px;
left:8px
}
.newsHeader h1{
    font-family: 'Roboto Condensed',sans-serif;
    margin-top: 10px;
    position: relative;
    display: inline-block;
}
.newsHeader h1::before{
    content:'';
    position: absolute;
    top: 110%;
    left: -5%;
    width: 50%;
    height: 3px;
    background-color:#02325F;
}
.newsHeader h1::after{
    content:'';
    position: absolute;
    top: 110%;
    left: 55%;
    width: 50%;
    height: 3px;
    background-color:#02325F;
}
.chiziq{
    width:50%;
    height:5px;
    background-color:darkblue;
    margin:auto;
    margin-top: 30px;
    border-radius: 30px;
}
.cardNews{
margin-top:30px;
    width: 350px;
    /* height: 500px !important; */
    border:1px solid rgba(0,0,0,0.2) !important;
    position: relative;
    margin-left: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.cardNews img{
    width: 100%;
   
    position: relative;
}
.dateNews{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0px 0px 20px 0px;
    width: 160px;
    height: 30px;
    background-color: #02325F;
    color: white;
    font-size: 16px;
    
}
.dateNews h1{
    color:white;
    
    font-size:23px

}
.cardNews h2{
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 10px;
    
    padding:20px 10px;
}
.dateNews i{margin-right:10px}
.btnNews{
    display: inline-block;
    margin-top: 10px;
    background-color: #72A5B9 !important;
    padding:5px 35px !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 550;
    cursor: pointer;
    position: absolute;
    top: 85%;
    left: 32%;
}


@media screen and (max-width:566px) {
    .cardNews{
        margin-left: 26px !important;
        width: 90% !important;
        margin-bottom: 20px;
        /* height: 500px !important; */
    }
    .dateNews{
        top: 0%;
    }
    .btnNews{
        font-size: 20px !important;
        top: 80%;
        left: 32%;
    }
}
@media  screen and (min-width:567px) {
    .news{
        height: 600px;
        
        padding:0 5% !important;
  }
    .cardNews{
        width: 90% !important;
        /* height: 500px !important; */
        margin-bottom: 20px;
    }
    .dateNews{
        top: 0%;
    }
    .btnNews{
        font-size: 20px !important;
    }
}
@media screen and (min-width:768px){
    .news{
        height: 750px;
        padding:0 2% !important;
  }
    .cardNews{
        width: 100%;
        margin-bottom: 20px;
        /* height: 500px !important; */
    }
    .btnNews{
        font-size: 20px !important;
        top: 80%;
        left: 30%;
    }
    .dateNews{
        top: 0%;
    }
}
@media screen and (min-width:992px){
    .news{
        height: 600px;
        
        padding:0 2% !important;
        text-align: left !important;
  }
    .cardNews{
        width: 98% !important;
        margin-bottom: 20px;
        /* height: 500px !important; */
    }
    .btnNews{
        font-size: 20px !important;
    }
    .dateNews{
        top: 38.9%;
    }
}
@media screen and (min-width:1200px) {
    .news{
        padding:0 10%;
        
    }
    .cardNews{
        width: 98% !important;
        /* height: 500px !important; */
    }
    .dateNews{
        top: 0% !important;
    }
}
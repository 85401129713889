.elon ul{list-style:none; padding:80px 50px; display: flex; justify-content: center; flex-wrap: wrap;}
.elon li{width:100%; padding:20px;}
.elon li div{box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;  padding-bottom: 10px;
     border-radius:10px; transition: 0.2s;}
.elon li div p{padding:20px 20px 0px 20px;}
.elon hr{margin:0px}
.clock{font-size: 14px; color:grey; text-align: right;}
.ned{font-size:16px;   height:auto;display:flex; align-items:center;}
.down{text-align:center; cursor: pointer;font-size: 20px; color:rgba(0, 0, 0, 0.782); transition: 0.2s; margin-top:-15px;margin-bottom: -3px; }
.elon li div:hover .down{color:rgb(0, 38, 255)}
.elon li div:hover{box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;}
.down:hover{color:rgb(9, 216, 9)}
@media (max-width:900px){
    .elon li{width:100%}
}
.bolimlar{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.butt{width:300px; display: block; margin-bottom: 30px;  padding:10px; text-align: center; background-color: white; color:#02325F;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
    .butt:hover{color:white; background-color: #02325F;}
.firstBolim h1{
    font-size: 33px !important;
    width: 800px;
    color:white;
    opacity: 0.9;
    margin-left: 130px;
    margin-bottom: 50px;
    text-align: left;
    margin-top: 40px;
}
.firstBolim{
    background-color:#02325F;
    padding:5%;
}
.headingBolim{
    display: flex;
    justify-content: center;
    align-items:center;
}
.headingBolim h2{
    color:white;
    opacity: 0.9;
    font-family: 'Roboto Condensed',sans-serif;
}
.cardsBolimlar1{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -20px;
    margin-left: 10px;
}
.cardsBolimlar2{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 30px;
}
.cardsBolimlar3{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 30px;
}
.cardsBolimlar4{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -20px;
}

.secondBolim{
    padding:3%;
    background-color:#02325F
}
.headingSecondBolim{
    display: flex;
    justify-content: center;
    align-items: center;
}
.headingSecondBolim h1{
    color:white;
    opacity: 0.9;
    font-family: 'Roboto Condensed',sans-serif;
}
.imageSecondBolim{
    background-color:#02325F;
    padding:3%;
}
.cardsBolimlar5{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -50px;
    color:black;
    margin-left: 10px;
}
.cardsBolimlar6{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 0px;
    color:black;
    margin-left: 12px;
}
.thirdBolim{
    background-color:#02325F;
}
.cardsBolimlar7{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -50px;
    margin-left: 30px !important;
    color:black;
    margin-left: 12px;
}
.cardsBolimlar8{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 15px;
    color:black;
    margin-left: 12px;
}
.cardsBolimlar9{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 15px;
    color:black;
    margin-left: 12px;
}
.cardsBolimlar10{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 15px;
    color:black;
    margin-left: 12px;
}
.cardsBolimlar11{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -30px;
    margin-left: 30px !important;
    color:black;
    margin-left: 12px;
}
.cardsBolimlar12{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 100%;
    text-align: left;
    background-color:white;
    height: 110px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 0px;
    margin-left: 10px;
    color:black;
}
.cardsBolimlar13{
    border-radius:20px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    padding:20px;
    text-align:justify;
    font-size:15px;
    width: 60%;
    text-align: left;
    background-color:white;
    height: 130px;
   
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: -60px;
    margin-left: 370px;
    color:black;
}

@media screen and (max-width:767px) {
    .firstBolim h1{
        font-size: 30px !important;
        width: 100%;
        margin-left: 0px;
    }
    .headingBolim{
        margin-top: 30px !important;
    }
    .cardsBolimlar1{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px;
        height: 80px !important;
    }
    .cardsBolimlar2{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        height: 80px !important;
    }
    .cardsBolimlar3{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        height: 80px !important;

    }
    .cardsBolimlar4{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        height: 80px !important;

    }
    .cardsBolimlar5{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px;
    }
    .cardsBolimlar6{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 0px;
    }
    .cardsBolimlar7{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px !important;
    }
    .cardsBolimlar8{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 0px;
    }
    .cardsBolimlar9{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 0px;
    }
    .cardsBolimlar10{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top:20px;
        margin-left: 0px;
    }
    .cardsBolimlar11{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 0px !important;
    }
    .cardsBolimlar12{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 00px;
    }
    .cardsBolimlar13{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        width: 100%;
        height: 200px;
        margin-top: 20px;
        margin-left: 0px;
        margin-bottom: 20px;
    }
}
@media screen and (min-width:768px) {
    .firstBolim h1{
        font-size: 30px !important;
        width: 100%;
        margin-left: 0px;
    }
    .headingBolim{
        margin-top: 30px !important;
    }
    .cardsBolimlar1{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 10px;
        margin-left: 0px;
    }
    .cardsBolimlar2{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 10px;
        height: 115px !important;
    }
    .cardsBolimlar3{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        height: 115px !important;

    }
    .cardsBolimlar4{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        height: 115px !important;

    }
    .cardsBolimlar5{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px;
        height: 100px;
    }
    .cardsBolimlar6{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px;
        height: 100px !important;
    }
    .cardsBolimlar7{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px !important;
        height: 100px;
    }
    .cardsBolimlar8{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 0px;
        height: 100px !important;
    }
    .cardsBolimlar9{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 0px;
        height: 100px !important;

    }
    .cardsBolimlar10{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top:20px;
        margin-left: 0px;
        height: 100px !important;

    }
    .cardsBolimlar11{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 30px !important;
        margin-left: 220px !important;
        height: 100px !important;

    }
    .cardsBolimlar12{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 20px;
        margin-left: 200px;
    }
    .cardsBolimlar13{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        width: 100%;
        height: 200px !important;
        margin-top: 20px;
        margin-left: 0px;
        margin-bottom: 20px;
        height: 150px !important;
    }
}
@media screen and (min-width:1200px) {
    .firstBolim h1{
        font-size: 33px !important;
        width: 800px;
        margin-left: 30px;
    }
    .cardsBolimlar1{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: -20px;
        margin-left: 10px;
        height: 150px !important;
    }
    .cardsBolimlar2{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 30px;
        height: 170px !important;

    }
    .cardsBolimlar3{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 30px;
        height: 170px !important;

    }
    .cardsBolimlar4{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: -20px;
        height: 150px !important;

    }
    .headingSecondBolim h1{
        font-size: 30px;
    }
    .cardsBolimlar5{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: -50px;
        margin-left: 10px;
        height: 130px;
    }
    .cardsBolimlar6{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: 0px;
        margin-left: 12px;
        height: 150px !important;
    }
    .cardsBolimlar7{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: -50px;
        margin-left: 30px !important;
        height: 150px;

    }
    .cardsBolimlar8{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        height: 180px !important;
        margin-top: 15px;
        margin-left: 12px;

    }
    .cardsBolimlar9{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        height: 180px !important;
        margin-top: 15px;
        color:black;
        margin-left: 12px;

    }
    .cardsBolimlar10{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        height: 180px !important;
        margin-top: 15px;
        color:black;
        margin-left: 12px;
    }
    .cardsBolimlar11{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        margin-top: -60px !important;
        margin-left: 30px !important;
        color:black;
        margin-left: 12px;
        height: 170px !important;

    }
    .cardsBolimlar12{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        height: 150px;
        margin-top: 0px;
        margin-left: 10px;
        color:black;
    }
    .cardsBolimlar13{
        border-radius:20px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding:20px;
        text-align:justify;
        font-size:15px;
        width: 60%;
        height: 165px;
        margin-top: -60px;
        margin-left: 190px;
        color:black;
        height: 190px !important;
    }
    .cardBolimlar5{
        height: 150px;
    }
}
.sliderHomiy{
width: 100%;
height: max-content;
text-align: center;
}
.sliderHomiy h1{
    margin-top: 20px;
    font-size: 40px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 800;
    position: relative;
    display: inline-block;
    margin-bottom: 60px;
    color:#02325F;
}
.sliderHomiy h1::before{
    content:'';
    position: absolute;
    top: 110%;
    left:-5%;
    width: 50%;
    height: 3px;
    background-color:#02325F ;
}
.sliderHomiy h1::after{
    content:'';
    position: absolute;
    top: 110%;
    left:55%;
    width: 50%;
    height: 3px;
    background-color: #02325F;
}
.sliderHomiy img{
    width:180px;
    height: 140px;
    margin-bottom: 40px;
}
.sliderDiv{
    width: 100%;
    height: 600px;
    position: relative;
    background: url('../../../img/slider-2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider2Div{
    width: 100%;
    height: 600px;
    position: relative;
    background: url('../../../img/a28c0fc4c89729ed04842.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider3Div{
    width: 100%;
    height: 600px;
    position: relative;
    background: url('../../../img/avKUImR_W4RYXkIAXldMWx47qgo03FYo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider4Div{
    width: 100%;
    height: 600px;
    position: relative;
    background: url('../../../img/6fb78d0d6424120ad77eb.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sliderDiv img{
    width: 100%;
    z-index: -10 !important

}
.sliderInsideDiv {
    position: absolute;
    top: 50%;
    left: 5%;
    width: 35% !important;
    height: max-content;
    background-color: black;
    opacity: 0.7;
    z-index: 10 !important;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:10px;
    text-align: center;
}
.sliderInsideDiv h1{
    color:white;
    font-size: 35px;
    opacity: 1;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 900;
}
.sliderInsideDiv h2{
    color:white ;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 30px !important;
}

@media screen and (max-width:566px){
    .sliderInsideDiv {
        top: 55% !important;
        left: 15% !important;
        width: 70% !important;
        padding:0px !important;
    }
}
@media screen and (min-width:567px){
    .sliderInsideDiv {
        top: 59% !important;
        left: 15% !important;
        width: 70% !important;
        padding:0px !important;
    }
}
@media screen and (min-width:768px){
    .sliderInsideDiv {
        top: 59% !important;
        left: 15% !important;
        width: 70% !important;
        padding:0px !important;
    }
}
@media screen and (min-width:992px){
    .sliderInsideDiv {
        top: 50% !important;
        left: 10% !important;
        width: 45% !important;
        padding:10px !important;
    }
}
@media screen and (min-width:1200px){
    .sliderInsideDiv {
        top: 50% !important;
        left: 5% !important;
        width: 35% !important;
        padding:10px !important;
    }
}
.afzalliklar{
    width: 100%;
    height: max-content;
    background-color: #02325F;
    text-align: center;
    padding: 0 10% !important;
}
.afzalliklar h1{
    margin-top: 20px;
    font-size: 40px;
    font-weight: 800;
    font-family: 'Roboto Condensed',sans-serif;
    position: relative;
    display: inline-block;
    margin-bottom: 90px;
    color:white;
    opacity: 0.99;
}
.afzalliklar h1::before{
    content:'';
    position: absolute;
    top: 120%;
    left: -5%;
    width: 50%;
    height: 4px;
    background-color:white;
    opacity: 0.99;
}
.afzalliklar h1::after{
    content:'';
    position: absolute;
    top: 120%;
    left: 55%;
    width: 50%;
    height: 4px;
    background-color:white;
    opacity: 0.99;
}
.cardAfzalliklar{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    text-align: left;
    margin-bottom: 30px;
}
.cardAfzalliklar h2{
    font-size: 17px;
    color: white;
    font-family: 'Open Sans',sans-serif;
    opacity: 0.99;
}
.cardAfzalliklar div:first-child{
    margin-right: 20px;
}
.cardAfzalliklar div:last-child{
width: 250px;
text-align: center;
height: max-content;
}


@media screen and (max-width:566px){
    .afzalliklar{
        padding: 0 5% !important;
    }
   
    .cardAfzalliklar div:last-child{
    width: 100%;
    }
    
}
@media screen and (min-width:567px){
    .afzalliklar{
        padding: 0 5%!important;
    }
   
    .cardAfzalliklar div:last-child{
    width: 100%;
    }
    
}
@media screen and (min-width:768px) {
    .afzalliklar{
        padding: 0 5% !important;
    }
    .cardAfzalliklar div:last-child{
        text-align: left;
        width: 240px;
    }
    
}
@media screen and (min-width:992px) {
    .afzalliklar{
        padding: 0 5%  !important;
    }
    .cardAfzalliklar div:last-child{
        text-align: left;
        width: 240px;
    }
}
@media screen and (min-width:1200px) {
    .afzalliklar{
        padding: 0 5% !important;
    }
    .cardAfzalliklar div:last-child{
        width: 250px;
    }
}
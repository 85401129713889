.body {
    font-family: 'lato', sans-serif;
    padding-top: 33px;
}

  .container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
  }


@media(min-width:768px){
hr{
    margin-bottom: 25px;
}
.responsiveT li{
    cursor: pointer;
    list-style: none;
        border-radius: 3px;
        padding: 15px 30px;
        margin-bottom: 25px;
      transition: all;
      transition-duration: 0.4s;
}
.responsiveT .tableR:hover{
    transform: scale(1.006);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sarlavha{
    text-align: center;
    font-family: serif;
    font-weight: lighter;
    margin: 40px 0px;
}
}

.responsiveT .tableR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.1);
border-radius: 30px;  
}



  
@media (max-width: 767px) {
    hr{
        margin-bottom: 11px;
    }
    .responsiveT li{
color: blue;
        list-style: none;
            border-radius: 3px;
            padding: 15px 10px;
            margin-bottom: 11px;
    }

    .sarlavha{
        font-size: 22px;
        text-align: center;
        font-family: serif;
        font-weight: lighter;
        margin:20px 8px 30px 8px;
    }
  }

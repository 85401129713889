.aboutIndex{
    text-align: left;
    padding: 5% 20%;
    padding-left: 25%;
    transition: all 0.3s;
    overflow:hidden !important;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url('../../../img/about.jpg');
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
}
.aboutOuter{
    overflow:  hidden !important;
    padding:0 !important;
}

.aboutIndex h2{
    display: inline-block;
    font-size: 40px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 800;
    position: relative;
    color:white;
    opacity: 0.9;
}
.aboutIndex h2::before{
    content: '';
    position: absolute;
    top: 50%;
    left: -70%;
    width: 50%;
    height: 5px;
    background-color: white !important;
}
.aboutIndex h2::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 200% !important;
    width: 10px;
    height: 130px;
    background-color: white !important;
}
.aboutIndex h1{
    display: inline-block;
    font-size: 40px;
    font-family: 'Roboto Condensed',sans-serif;
    font-weight: 800;
    position: relative;
    color:white;
    opacity: 0.9;
}
.aboutIndex h1::before{
    content: '';
    position: absolute;
    top: 50%;
    left: -70%;
    width: 50%;
    height: 5px;
    background-color: white !important;
}
.aboutIndex h1::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 200% !important;
    width: 6px;
    height: 130px;
    background-color: white !important;
}
.aboutIndex p{
    font-family: 'Open Sans',sans-serif;
    font-size: 20px;
    transition: all 0.2s;
    color:white;
    opacity: 0.9;
}
@media screen and (max-width:566px){
    .aboutIndex{
        text-align: justify;
        padding: 10px;
        padding-left: 0%;
    }
    .aboutIndex h2::before{
        top: 110%;
        left: -5%;
        height: 4px;
    }
    .aboutIndex h2::after{
        top: 110%;
        left: 55% !important;
        width: 50%;
        height: 4px;
    }
    .aboutIndex h1::before{
        top: 110%;
        left: -5%;
        height: 4px;
    }
    .aboutIndex h1::after{
        top: 110%;
        left: 55% !important;
        width: 50%;
        height: 4px;
    }
}
@media screen and (min-width:567px){
    .aboutIndex{
        text-align: justify;
        padding: 5%;
        padding-left: 0%;
    }
    .aboutIndex h2::before{
        top: 110%;
        left: -5%;
        width: 50%;
        height: 4px;
    }
    .aboutIndex h2::after{
        top:110%;
        left: 55% !important;
        width: 50%;
        height: 4px
    }
    .aboutIndex h1::before{
        top: 110%;
        left: -5%;
        height: 4px;
    }
    .aboutIndex h1::after{
        top: 110%;
        left: 55% !important;
        width: 50%;
        height: 4px;
    }
}

@media screen and (min-width:768px){
    .aboutIndex{
        text-align: left;
        padding: 5% 20%;
        padding-left: 20%;
    }
    .aboutIndex h2::before{
        content: '';
        position: absolute;
        top: 50%;
        left: -70%;
        width: 50%;
        height: 5px;
        background-color: #72A5B9;
    }
    .aboutIndex h2::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 640% !important;
        width: 5px;
        height: 130px;
    }
    .aboutIndex h1::before{
        content: '';
        position: absolute;
        top: 50%;
        left: -60%;
        width: 40%;
        height: 5px;
        background-color: #72A5B9;
    }
    .aboutIndex h1::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 260% !important;
        width: 5px;
        height: 130px;
    }
}
@media screen and (min-width:992px){
    .aboutIndex{
        text-align: left;
        padding: 5% 20%;
        padding-left: 25%;
        overflow-y: hidden;
    }
    .aboutIndex h2::after{
        left: 600% !important;

    }
    .aboutIndex h1::after{
        left: 230% !important;

    }
}
@media screen and (min-width:1200px){
   
    .aboutIndex h2::after{
        left: 700% !important;

    }
    .aboutIndex h1::after{
        left: 300% !important;

    }
}



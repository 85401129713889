.header img{width:35%;}
.header{display:flex;align-items: center;
      height:140px; width:100%; margin-top:0px; position:relative; background-image: url('../img/pattern.jpg'); background-repeat:repeat-x;
       background-size: 10% 30%; padding-top:57px; background-position: 0px 0px;
      }
      .row{margin-top:-8px; width:120%;}
.whiteBack{background:linear-gradient(to bottom, #ffffffe3, transparent); width:100%; height:60%; position: absolute; top:0px; left:0px}
.headerImg, .headerInput{display:flex;align-items:center; justify-content:center;background:linear-gradient(to right, rgba(2, 2, 192, 0.74), white); width:100%; height:100%}
.headerInput{background:linear-gradient(to right, white, rgba(2, 2, 192, 0.74));  display:flex; align-items:center; }
.headerImg{position: relative; overflow: hidden;}
.flag{position: absolute; top:0px; left:-30px; width:400px; height:20px; transform: rotate(-45deg);}

.headerInput form{
    position: relative;
    left:50%;
    transform: translate(-50%,-50%);
      transition: all 1s;
    width: 40px;
    height: 40px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
display:flex; align-items: center; justify-content:center;
}
.headerText h1{text-align: center; font-weight: bold;}
.headerText h4{text-align: center;}
.headerInput input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;;
    height: 32.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 16px;
    border-radius: 20px;
    padding: 0 20px;
}

.headerInput i{
    box-sizing: border-box;
    padding: 10px;
    width: 32.5px;
    height: 32.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #02325f;
    display:flex; justify-content:center; align-items:center;
    text-align: center;
    font-size: 16px;
    transition: all 1s;
}
.headerInput form{
    margin-right:20px
}
.headerInput form:hover{
    width: 200px;
    cursor: pointer;
}

.headerInput form:hover input{
    display: block;
}

.headerInput form:hover i{
    background: #02325f;
    color: white;
}
.til img{width:25px; cursor:pointer; height:15px; margin-left:10px; margin-top:30px;}
.til{position: absolute; top:80px; right:30px}

.img{display: flex; justify-content: center; align-items:center;}
@media (max-width:990px){
    .text{display:none}
    .in{display:none}
    .img{width:105%; padding-top: 15px;}
    .img img{width:150px;}
    .row{margin-top: 31px; width:130%}
    .flag{top:10px; left:-100px; height:20px}
    .til{top:130px}
} 
@media (max-width:720px){
    .flag{left:-50px}
}

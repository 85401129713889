@import url('https://fonts.googleapis.com/css?family=Open+Sans');
*{
    margin: 0px;
    padding: 0px;
}

.mat{
    background-color: #faf8f8;
	font-family: 'Open Sans', sans-serif;
    width: 100%;
padding: 42px 0px;
}
.container1{
    padding: 23px 12px;
}
.featuresList{
    padding: 0px 32px;
}
.pricingBox .win{
    padding: 29px 0px;
    width: 100%;
    background-color: #00000099;
   
}
.pricingBox1 .win{
    padding: 29px 0px;
    width: 100%;
    background-color: #01010149;
 }
.pricingBox{
    overflow: hidden;
}
.pricingBox1{
    overflow: hidden;
}

@media (min-width: 992px) {
.sar{
    text-align: center;
    margin-bottom: 43px;
}
.featuresList li{
    font-size: 22px;
}
    .container1{
        margin: 0px auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .pricingBox {
    width: 45%;
    text-align: center;
	background-image: url('http://cdn.beta.qalampir.uz/uploads/i8/f_2KxkBV0L1vpRxqVZvaXGojGLwT74ig.jpg');
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.pricingBox1 {
    width: 45%;
    text-align: center;
	background-image: url('../img/bg1.jpg');
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.win h5{
    color: #ffffff;
    text-transform: uppercase;
}

.price{
      margin: 38px 0px;
    font-size: 23px;
}
.win li{
    padding: 16px 0px;
   width: 100%;
    list-style: none;
}
.win li:hover{
    padding: 14px 0px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;

}
}
@media (min-width: 768px) and (max-width: 991px){
    .sar{
        display: block;
        font-size: 20px;
        text-align: center;
        margin: 13px 9px 40px 9px;
    }
    .container1{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .pricingBox {
    width: 355px;
    text-align: center;
	background-image: url('http://cdn.beta.qalampir.uz/uploads/i8/f_2KxkBV0L1vpRxqVZvaXGojGLwT74ig.jpg');
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.pricingBox1 {
    width: 355px;
    text-align: center;
	background-image: url('../img/bg1.jpg');
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.win h5{
    color: #ffffff;
    text-transform: uppercase;
}
.win p{
    font-size: 24px;
    margin: 38px 0px;
}

.win li{
    font-size: 20px;
    margin: 0px auto;
    padding: 14px 0px;
   width: 80%;
    list-style: none;
}
.win li:hover{
    padding: 12px 0px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;

}
}
@media (max-width:767px) {
    .sar{
        font-size: 16px;
        text-align: center;
        margin: 3px auto;
        margin-bottom: 37px;
    }
    .container1{
        width: 100%;
    }
    .pricingBox {
    max-width: 450px;
        width: 94%;
    margin: 22px auto;
    text-align: center;
	background-image: url('http://cdn.beta.qalampir.uz/uploads/i8/f_2KxkBV0L1vpRxqVZvaXGojGLwT74ig.jpg');
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.pricingBox1 {
    max-width: 450px;
    width: 94%;
    margin: 22px auto;
    text-align: center;
	background-image: url('../img/bg1.jpg');
	box-shadow:inset 0px 2px 15px 0px rgba(0,0,0,0.5);
	border-radius: 4px;
    background-size: 100% 100%;
	background-repeat:no-repeat;
	color: #ffffff;
}
.win h5{
    color: #ffffff;
    text-transform: uppercase;
}
.win p{
    font-size: 17px;
    margin: 30px 0px;
}
.win li span{
    display: none;
}
.win li{
    margin: 0px auto;
    padding: 10px 0px;
    width: 90%;
    list-style: none;

}
.win li:not(:last-of-type) {
    border-bottom: 2px solid white;
}
}
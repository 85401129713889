.konkurslar{
    text-align: center;
}
.containerKonkurslar{
    padding-left: 0px !important;
    padding-right:30px !important;
}
.cardsKonkurslar{background-color:white}
.konkurslar h1{
    font-family: 'Roboto Condensed',sans-serif;
    margin-top: 20px;
    font-weight: 900;
    position: relative;
    display: inline-block;
    color:#02325F;
}
.konkurslar h1::after{
    content:'';
    position:absolute;
    top: 110%;
    left: -5%;
    width: 50%;
    height: 3px;
    background-color:#02325F;
}
.konkurslar h1::before{
    content:'';
    position:absolute;
    top: 110%;
    left: 55%;
    width: 50%;
    height: 3px;
    background-color:#02325F;
}
.cardsKonkurslar{
    width: 100%;
    height: 430px;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
    padding: 30px;
    margin: 20px;  
    border-top: 3px solid hsl(212, 86%, 64%);
    text-align: left;
}
.cardsKonkurslar h2{
    height: 80px;
    font-size: 20px;
    font-weight: 900;
    margin-top: 1px;
}

.cardsKonkurslar p{
    color:rgba(0,0,0,0.5) !important;
    cursor: pointer;
    height: 110px !important;

}
.cardsKonkurslar  span{
font-size: 12px;
color:hsl(229, 6%, 66%);
}
.cardsKonkurslar h6{
    margin-top: 10px;
}
.btnKonkurslar{
    display: block !important;
    color:white !important;
    background-color:hsl(212, 86%, 64%);
    border:none;
    margin-bottom: 10px;
}
.btnKonkurslar h5{
    color:white;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Roboto Condensed',sans-serif;
    margin-top: -10px;
}
@media screen and (max-width:566px) {
    .cardsKonkurslar{
        width: 100%;
        height: 370px;
    }
    .cardsKonkurslar h2{
        height: 45px !important;
    }
    .cardsKonkurslar p{
        color:hsl(212, 86%, 64%);
        cursor: pointer;
        height: 70px !important;
    
    }
}
@media screen and (min-width:567px) {
    .cardsKonkurslar{
        width: 100%;
        height: 510px;
    }
    .cardsKonkurslar h2{
        height: 85px;
    }
    .cardsKonkurslar p{
        color:hsl(212, 86%, 64%);
        cursor: pointer;
        height: 150px !important;
    
    }
}
@media screen and (min-width:768px) {
    .cardsKonkurslar{
        width: 100%;
        height: 445px;
    }
    .cardsKonkurslar h2{
        height: 80px;
    }
    .cardsKonkurslar p{
        color:hsl(212, 86%, 64%);
        cursor: pointer;
        height: 115px !important;
    
    }
}
@media screen and (min-width:992px) {
    .cardsKonkurslar{
        width: 100%;
        height: 490px !important;
    }
    .cardsKonkurslar h2{
        height: 90px !important;
    }
    .cardsKonkurslar p{
        color:hsl(212, 86%, 64%);
        cursor: pointer;
        height: 125px !important;
    
    }
}
@media screen and (min-width:1200px) {
    .cardsKonkurslar{
        width: 100%;
        height: 480px;
        text-align: left;
    }
    .cardsKonkurslar h2{
        height: 100px;
    }
    .cardsKonkurslar p{
        color:hsl(212, 86%, 64%);
        cursor: pointer;
        height: 130px !important;
    
    }
}
.navbarT a {
    text-decoration: none;
    color: #1E1E23;
    opacity:1;
    font-family: 'work sans', sans serif;
    font-size: 1.5em;
    font-weight: 400;
    transition: 200ms;
  }
  .navbarT a:hover {
    opacity:0.5;
  }
  .navbarT ul {
    padding: 0;
    list-style-type: none;
  }
  
  
  
  .navbarT .container {
    margin-top: 50px; 
    Display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbarT .phone {
    background-color: #36383F;
    border-radius: 40px;
    width: 300px;
    height: 600px;
    Display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 30px 50px 100px #85888C;
  }
  .navbarT .content {
    border: 1px solid #36383F;
    border-radius: 20px;
    width: 94%;
    height: 91%;
    background-color: #F5F6FA;
    overflow: hidden;
  }
  .navbarT nav {
    background-color: #1E1E23;
    height: 65px;
  }
  
  
  .navbarT #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .navbarT #menuToggle input
  {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  
  .navbarT #menuToggle span
  {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }
  
  .navbarT #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }
  
  .navbarT #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }
  
  .navbarT #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
  }
  .navbarT #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  .navbarT #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }
  
  .navbarT #menu
  {
    position: absolute;
    width: 180px;
    height: 400px;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    background-color: #F5F6FA;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }
  
  .navbarT #menu li
  {
    padding: 10px 0;
    transition-delay: 2s;
  }
  
  .navbarT #menuToggle input:checked ~ ul
  {
    transform: none;
  }